import request from "@/utils/request";

// 抽奖奖品列表
export function PrizeList(params){
    return request({
        url:'/admin/base/prizeList',
        method: 'get',
        params
        }
    )
}

// 获取奖品分类
export function GetTypeArr(params){
    return request({
        url:'/admin/base/getTypeArr',
        method: 'get',
        params
        }
    )
}


// 抽奖上传COS签名
export function PrizeCosToken(params){
    return request({
        url:'/admin/base/prizeCosToken',
        method: 'get',
        params
        }
    )
}

// 编辑抽奖奖品 
export function PrizeEdit(data){
    return request({
        url:'/admin/base/prizeEdit',
        method: 'put',
        data
        }
    )
}

// 调整抽奖概率 
export function PrizeChance(data){
    return request({
        url:'/admin/base/prizeChance',
        method: 'put',
        data
        }
    )
}
