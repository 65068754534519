<template>
  <div>
    <el-card>
      <el-table
        :data="prizeList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="类型" prop="type" align="center">
        </el-table-column>
        <el-table-column
          label="奖品名"
          prop="title"
          align="center"
        ></el-table-column>

        <el-table-column
          label="金币数量"
          prop="gold_num"
          align="center"
        ></el-table-column>

        <el-table-column
          label="现金价值"
          prop="cash_num"
          align="center"
        ></el-table-column>

        <el-table-column label="使用条件" prop="condition" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.condition == "" ? "无" : scope.row.condition }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <div
              @click="openThumb(scope.row.thumb_url)"
              class="thumb_box"
              v-if="scope.row.thumb"
            >
              <img :src="scope.row.thumb_url" alt="" />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="出现概率"
          prop="chance"
          align="center"
          width="350"
        >
          <template slot-scope="scope">
            <div class="progress_box">
              <el-slider
                class="chance_slider"
                v-model="scope.row.chance"
                :show-tooltip="false"
                @change="
                  changeChance(
                    scope.row.chance,
                    scope.row.id,
                    scope.row.chanceNum
                  )
                "
              ></el-slider>
              <span class="chance_num">{{ scope.row.chance }}%</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="toEditAdvert(scope.row)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 缩略图 -->
      <el-dialog
        title="缩略图详情"
        :visible.sync="detailsDialog"
        :center="true"
        width="40%"
      >
        <div style="width: 100%; height: 100%">
          <img style="width: 100%; height: 100%" :src="detailsImg" alt="" />
        </div>
      </el-dialog>

      <!-- 编辑奖品 -->
      <el-dialog
        title="编辑奖品"
        :visible.sync="addDialog"
        :center="true"
        width="40%"
        @close="closed"
        :close-on-click-modal="false"
      >
        <el-form :model="editForm" ref="editForm">
          <el-form-item el-form-item label="奖品类型" label-width="100px">
            <el-select
              v-model="editForm.type"
              placeholder="请选择奖品类型"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in typeArr"
                :key="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item el-form-item label="奖品名称" label-width="100px">
            <el-input
              v-model="editForm.title"
              placeholder="请输入奖品名称"
            ></el-input>
          </el-form-item>

          <el-form-item el-form-item label="缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
              style="width: 180px; height: 180px"
            >
              <img
                v-if="thumbSrc"
                style="width: 100%; height: 100%"
                :src="thumbSrc"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <div v-if="editForm.type == '金币'">
            <el-form-item el-form-item label="金币数" label-width="100px">
              <el-input
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="editForm.gold_num"
                placeholder="请输入金币数"
              ></el-input>
            </el-form-item>
          </div>

          <div v-if="editForm.type == '优惠券'">
            <el-form-item el-form-item label="现金价值" label-width="100px">
              <el-input
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="editForm.cash_num"
                placeholder="请输入现金价值"
              ></el-input>
            </el-form-item>
            <el-form-item el-form-item label="使用条件" label-width="100px">
              <el-input
                v-model="editForm.condition"
                placeholder="请输入使用条件"
                type="textarea"
                :rows="4"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label-width="40%" style="margin-top: 20px">
            <el-button type="primary" @click="editNow">立即修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
var COS = require("cos-js-sdk-v5");
import {
  PrizeList,
  GetTypeArr,
  PrizeCosToken,
  PrizeEdit,
  PrizeChance,
} from "@/api/prizeList.js";
export default {
  data() {
    return {
      prizeList: [],
      typeArr: [],
      addDialog: false,
      editForm: {
        id: "",
        type: "",
        title: "",
        thumb: "",
        gold_num: "",
        cash_num: "",
        condition: "",
      },
      thumbSrc: "",
      detailsImg: "",
      detailsDialog: false,

      chanceCount: 0,
    };
  },
  created() {
    this.getGetTypeArr();
    this.getPrizeList();
  },
  methods: {
    openThumb(src) {
      this.detailsDialog = true;
      this.detailsImg = src;
    },
    //   广告列表
    getPrizeList() {
      PrizeList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        // this.prizeList = res.data;

        this.chanceCount = res.data.chance;
        res.data.list.forEach((item) => {
          item.chanceNum = item.chance;
        });
        this.prizeList = res.data.list;
        console.log(this.prizeList);
      });
    },

    // 获取获取类型分类
    getGetTypeArr() {
      GetTypeArr().then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.typeArr = res.data;
      });
    },

    // 修改客服出现概率
    changeChance(per, id, numb) {
      let data = {
        id: id,
        chance: per,
      };

      let num = 100 - this.chanceCount;
      if (per > num && num !== 0 && numb < per) {
        data.chance = num + numb;
      }
      PrizeChance(data).then((res) => {
        this.getPrizeList();
        if (res.code !== 1) {
          return;
        }
        this.$message.success("修改奖品出现概率成功");
        this.getPrizeList();
      });
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      PrizeCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.editForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击编辑按钮
    toEditAdvert(row) {
      this.addDialog = true;
      this.editForm = JSON.parse(JSON.stringify(row));
      this.thumbSrc = this.editForm.thumb_url;
    },

    // 点击立即修改按钮
    editNow() {
      if (this.editForm.type == "") {
        this.$message.error("请选择类型");
        return;
      } else if (this.editForm.title == "") {
        this.$message.error("请输入奖品名称");
        return;
      } else if (this.editForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.editForm.type == "金币" && this.editForm.gold_num == "") {
        this.$message.error("请输入奖励金币数");
        return;
      } else if (
        this.editForm.type == "优惠券" &&
        this.editForm.cash_num == ""
      ) {
        this.$message.error("请输入现金价值");
        return;
      } else if (
        this.editForm.type == "优惠券" &&
        this.editForm.condition == ""
      ) {
        this.$message.error("请输入使用条件");
        return;
      }
      let data = {
        id: this.editForm.id,
        type: this.editForm.type,
        title: this.editForm.title,
        thumb: this.editForm.thumb,
        gold_num: this.editForm.gold_num,
        cash_num: this.editForm.cash_num,
        condition: this.editForm.condition,
      };
      console.log(data);
      PrizeEdit(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("编辑奖品成功");
        this.addDialog = false;
        this.getPrizeList();
      });
    },
    // 弹窗关闭事件
    closed() {
      this.editForm = {
        id: "",
        type: "",
        title: "",
        thumb: "",
        gold_num: "",
        cash_num: "",
        condition: "",
      };
      this.thumbSrc = "";
    },
  },
};
</script>

<style lang="less" scoped>
.el-card {
  padding-bottom: 50px;
}
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 90px;
    height: 90px;
  }
}

.tips {
  color: red;
}

.progress_box {
  display: flex;
  align-items: center;
  .chance_slider {
    flex: 1;
  }
  .chance_num {
    width: 50px;
  }
}

::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
